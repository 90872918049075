// draganel homepage company-rankings-desktop-maximized

#company-rankings-desktop-maximized {
    display: none;
    z-index: 1;
    background: rgba(0,0,0,0.8);
    padding: 20px 50px;
    position: fixed;
    bottom: 50px;
    right: -470px;
    z-index: 1000;
    .contents {
        padding: 16px;
        text-align: center;
        h3 {
            font-weight: normal;
            color: white;
            margin-bottom: 15px;
            margin-top: 0;
            font-size: 25px;
        }
    }
    #button-rounded {
        margin: 20px 0 10px;
        a {
            background-color: white;
            color: #4d4d4d;
            &.btn{
                margin: 0;
            }
            &:hover {
                text-decoration: none;
                color: #666;
            }
        }
    }
    .minimax {
        font-size: 10px;
        color: white;
    }
}
#company-rankings-desktop-minimized {
    display: none;
    background: rgba(0,0,0,0.8);
    position: fixed;
    bottom: 50px;
    -webkit-transform: rotate(-90deg); /* Safari and Chrome */
    -moz-transform: rotate(-90deg);   /* Firefox */
    -ms-transform: rotate(-90deg);   /* IE 9 */
    -o-transform: rotate(-90deg);   /* Opera */
    transform: rotate(-90deg);
    bottom: 100px;
    color: #fff;
    cursor: pointer;
    display: block;
    padding: 16px;
    right: -47px;
    z-index: 1;
    -webkit-transform-origin: 50%  51%; 
    p.minimax {
        margin: 0 0 10px;
    }
}
#company-rankings-mobile-maximized {
    display: none;
    position: absolute;
    z-index: 1;
    background: rgba(0,0,0,0.8);
    padding: 5%;
    position: fixed;
    bottom: 0;
    right: 0px;
    width: 90%;
    text-align: center;

    h6 {
        color : #FFFFFF;
        font-size: 30px;
    }
    #button-rounded {
        a {
            background-color: white;
            color: #4d4d4d; 
            &:hover {
                text-decoration: none;
                color: #666;
            }
        }
    }
}
@media screen and (max-width: 479px) {
    #company-rankings-desktop-maximized, #company-rankings-desktop-minimized {
        display: none !important;
    }
    /*#company-rankings-mobile-maximized {
        display: block;
    }*/
}
@media screen and (min-width: 480px) {
    #company-rankings-mobile-maximized {
        display: none !important;
    }
}

.promo-basics {
    .container {
        @media (max-width: 480px) {
            width: auto;
        }
    }
    .promo-big .promo-big__message {
        @media (max-width: 480px) {
            width: 95% !important;
        }
    }
    .promo-big .promo-big__button {
        @media (max-width: 480px) {
            width: 95% !important;
        }
    }
}

body.page-upgrade-footer {
    margin-bottom: 10px;
}

.page-upgrade-footer {
    footer {
        ul {
            li {
                &:nth-child(1) {
                    display: none;
                }
                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(4) {
                    display: none;
                }
            }
        }
    }
    .footer-section {
        padding: 21px 0 15px 0 !important;
    
        ul.footer-navigation {
            text-align: center;
            li {
                position: absolute;
                left: 0;
                right: 0;

            }
        }
    }
}


.page-upgrade-nomenu {
    .navbar-nav,
    .navbar-fixed-top .navbar-header .navbar-toggle {
        display: none;
    }
    .navbar__signin-employers {
        display: none;
    }
}

#whaaa {
    margin-left: 8px;
}

.container {
    @media (max-width: 768px) {
        width: 720px !important;
    }
    @media (max-width: 767px) {
        width: auto !important;
    }
}